import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import ListIcon from "@material-ui/icons/List";
import InfoIcon from "@material-ui/icons/Info";
import ClearIcon from "@material-ui/icons/Clear";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const dialogDetailsInputFields = new Set([]);

const UserSSI = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [userSSIEntries, setUserSSIEntries] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [acquireVerifiableAuthorization, setAcquireVerifiableAuthorization] =
    React.useState(
      Object.values(ApiService.selfSovereignIdentityProviders).reduce(
        (a, v) => ({ ...a, [v]: false }),
        {}
      )
    );
  const [showRegisterDidDocument, setShowRegisterDidDocument] = React.useState(
    Object.values(ApiService.selfSovereignIdentityProviders).reduce(
      (a, v) => ({ ...a, [v]: false }),
      {}
    )
  );
  const [registerDidDocument, setRegisterDidDocument] = React.useState(
    Object.values(ApiService.selfSovereignIdentityProviders).reduce(
      (a, v) => ({ ...a, [v]: false }),
      {}
    )
  );

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showRegisterModal, setShowRegisterModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("acquire")
  );

  // pagination sorting
  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  // Dialog fields
  const [modalServiceProvider, setModalServiceProvider] = React.useState("");
  const [modalOnboard, setModalOnboard] = React.useState([1]);
  const [modalVerifiableAuthorization, setModalVerifiableAuthorization] =
    React.useState("");
  const [modalClient, setModalClient] = React.useState("");
  const [modalDid, setModalDid] = React.useState("");

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [formatDetails, setFormatDetails] = React.useState(true);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    if (queryParams.get("parentId")) {
      params.parentId = queryParams.get("parentId");
    }
    if (queryParams.get("parentType")) {
      params.parentType = queryParams.get("parentType");
    }
    const returnData = await ApiService.loginRequired(signal, false);
    if (
      (returnData.superuser ||
        params.parentType === ApiService.parentTypes.organization) &&
      params.userId
    ) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowRegisterModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("acquire"));
    setModalOnboard([1]);
    setModalServiceProvider("");
    setModalVerifiableAuthorization("");
    setModalClient("");
    setModalDid("");
    setShowDetailsModal(false);
    setFormatDetails(true);
    setDetailsTitle("");
    setDetails({});
  };

  const handleAcquireVerifiableAuthorization = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const queryParams = await getQueryParams();
    const onboard = modalOnboard.length > 0 ? "true" : "false";

    const params = {
      userId: queryParams.userId,
      provider: modalServiceProvider,
      client: modalClient,
      verifiableAuthorization: modalVerifiableAuthorization,
      did: modalDid,
      onboard
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));

    try {
      await ApiService.acquireVerifiableAuthorization(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(
      t("verifiable-authorization-successfully-acquired")
    );
  };

  const handleRegisterDidDocument = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const queryParams = await getQueryParams();

    const params = {
      userId: queryParams.userId,
      provider: modalServiceProvider,
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));

    try {
      await ApiService.registerDidDocument(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("did-document-successfully-registered"));
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const downloadSSIEntry = async (ssiEntry) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        id: ssiEntry._id,
        exportJson: "true",
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      const responseData = await ApiService.downloadSingleUserSSIEntry(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = `${ssiEntry._id}.json`;
      a.click();
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleDownloadSSIEntry = (ssiEntry) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-ssi-entry")}?`}
        onConfirm={() => {
          downloadSSIEntry(ssiEntry);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{ssiEntry._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.encryptedFormatted = elem.encrypted.toString();
      elem.actions = (
        <div className="actions-right">
          {(elem.transactionStatus ===
            ApiService.jobTransactionStatus.written ||
            (elem.type === ApiService.selfSovereignIdentityTypes.did &&
              elem.provider ===
                ApiService.selfSovereignIdentityProviders.ebsi)) && (
            <a
              href={
                elem.provider === ApiService.selfSovereignIdentityProviders.ebsi
                  ? ApiService.populatePath(ApiService.ebsiDIDInfoURL, {
                      did: JSON.parse(elem.contents).id,
                    }).populatedPath
                  : ApiService.populatePath(ApiService.blockchainInfoURL, {
                      transactionId: elem.transactionId,
                    }).populatedPath
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip title={t("blockchain-info-url")}>
                <Button justIcon round simple color="rose" className="remove">
                  <InfoIcon />
                </Button>
              </Tooltip>
            </a>
          )}
          <Tooltip title={t("download-ssi-entry")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleDownloadSSIEntry(elem);
              }}
            >
              <CloudDownloadIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("ssi-entry-details")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleGetSingleSSIEntryDetails(elem);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-ssi-entry")}>
            <Button
              justIcon
              color="rose"
              simple
              className="remove"
              onClick={() => {
                handleDeleteUserSSIEntry(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeleteUserSSIEntry = (ssiEntry) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-ssi-entry")}?`}
        onConfirm={() => {
          deleteUserSSIEntry(ssiEntry);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${ssiEntry._id}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteUserSSIEntry = async (ssiEntry) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        id: ssiEntry._id,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      await ApiService.deleteSingleUserSSIEntry(params);
      operationResult(true, t("ssi-entry-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      if (queryParams.get("parentId")) {
        params.parentId = queryParams.get("parentId");
      }
      if (queryParams.get("parentType")) {
        params.parentType = queryParams.get("parentType");
      }
      const returnData = await ApiService.loginRequired(signal, false);
      if (
        (returnData.superuser ||
          params.parentType === ApiService.parentTypes.organization) &&
        params.userId
      ) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const checkValidVerifiableAuthorization = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
        };
        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        if (queryParams.parentType) {
          params.parentType = queryParams.parentType;
        }
        const providers = Object.values(
          ApiService.selfSovereignIdentityProviders
        );
        const verifiableAuthorization_ = {};
        const showRegisterDidDocument_ = {};
        const registerDidDocument_ = {};
        // eslint-disable-next-line no-unused-vars
        for (const provider of providers) {
          params.provider = provider;
          const { result, didRegistered } =
            await ApiService.checkValidVerifiableAuthorization(params, signal);
          verifiableAuthorization_[provider] = !result;
          showRegisterDidDocument_[provider] = result;
          registerDidDocument_[provider] = result && !didRegistered;
        }
        setAcquireVerifiableAuthorization({ ...verifiableAuthorization_ });
        setShowRegisterDidDocument({ ...showRegisterDidDocument_ });
        setRegisterDidDocument({ ...registerDidDocument_ });
      } catch (e) {
        console.error(e);
      }
    };

    const getSSIUserEntries = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        if (queryParams.parentType) {
          params.parentType = queryParams.parentType;
        }

        const responseData = await ApiService.getSSIUserEntries(params, signal);
        setUserSSIEntries(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        params.count = true;
        const { count: userSSIEntriesCount } =
          await ApiService.getSSIUserEntries(params, signal);
        setCount(userSSIEntriesCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await checkValidVerifiableAuthorization(signal);
        await getSSIUserEntries(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }
    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  const handleGetSingleSSIEntryDetails = async (ssiEntry) => {
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      id: ssiEntry._id,
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }
    try {
      const responseData = await ApiService.getSingleUserSSIEntry(params);
      setFormatDetails(true);
      setDetailsTitle(t("ssi-entry-details"));
      let details = { contents: responseData.contents };
      if (
        ssiEntry.type === ApiService.selfSovereignIdentityTypes.client ||
        ssiEntry.type === ApiService.selfSovereignIdentityTypes.did
      ) {
        details = JSON.parse(responseData.contents);
      }
      setDetails(details);
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setFormatDetails(true);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {formatDetails &&
              Object.entries(details).map(([fieldName, fieldValue]) => {
                let data;
                if (typeof fieldValue === "object") {
                  data = (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(fieldValue, undefined, 2)}
                    </div>
                  );
                } else {
                  if (dialogDetailsInputFields.has(fieldName)) {
                    data = (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: fieldValue.toString(),
                        }}
                      />
                    );
                  } else {
                    data = (
                      <React.Fragment>{fieldValue.toString()}</React.Fragment>
                    );
                  }
                }
                return (
                  <React.Fragment key={uuidv4()}>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ fontWeight: "400" }}
                    >
                      {dialogDetailsInputFields.has(fieldName) ? (
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {fieldName}
                        </FormLabel>
                      ) : (
                        fieldName
                      )}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={8}
                      className={classes.wordBreak}
                    >
                      {data}
                    </GridItem>
                  </React.Fragment>
                );
              })}
            {!formatDetails && (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(details, undefined, 2)}
              </div>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setShowDetailsModal(false);
              setFormatDetails(true);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("acquire-verifiable-authorization")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAcquireVerifiableAuthorization}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("provider")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="va-service-provider"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceProvider}
                    onChange={(e) => {
                      setModalServiceProvider(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "vaServiceProvider",
                      id: "vaServiceProvider",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("provider")}
                    </MenuItem>
                    {Object.values(ApiService.selfSovereignIdentityProviders)
                      .filter((x) => acquireVerifiableAuthorization[x])
                      .map((provider) => {
                        return (
                          <MenuItem
                            key={provider}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={provider}
                          >
                            {provider}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            {modalServiceProvider ===
              ApiService.selfSovereignIdentityProviders.ebsi && (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <FormLabel
                      className={
                        formClasses.labelHorizontal +
                        " " +
                        formClasses.labelHorizontalRadioCheckbox
                      }
                      style={{ float: "left" }}
                    >
                      {t("auto-onboard")}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6} style={{ float: "left" }}>
                    <div className={formClasses.checkboxAndRadio}>
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => {
                              const currentIndex = modalOnboard.indexOf(1);
                              const newChecked = [...modalOnboard];
                              if (currentIndex === -1) {
                                newChecked.push(1);
                              } else {
                                newChecked.splice(currentIndex, 1);
                              }
                              setModalOnboard(newChecked);
                            }}
                            checkedIcon={
                              <Check className={formClasses.checkedIcon} />
                            }
                            icon={
                              <Check className={formClasses.uncheckedIcon} />
                            }
                            classes={{
                              checked: formClasses.checked,
                              root: formClasses.checkRoot,
                            }}
                            checked={modalOnboard.length > 0}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot,
                        }}
                        label={t("auto-onboard")}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                {modalOnboard.length === 0 && (
                  <React.Fragment>
                    <GridContainer>
                      <GridItem xs={12}>
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {t("submit-your-data")}
                        </FormLabel>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {t("client")}
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="token"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: modalClient,
                            onChange: (e) => {
                              setModalClient(e.target.value);
                            },
                          }}
                          helpText={t("token")}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {t("verifiable-authorization")}
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="verifiable-authorization"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: modalVerifiableAuthorization,
                            onChange: (e) => {
                              setModalVerifiableAuthorization(e.target.value);
                            },
                          }}
                          helpText={t("verifiable-authorization")}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {"DID"}
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="did"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: modalDid,
                            onChange: (e) => {
                              setModalDid(e.target.value);
                            },
                          }}
                          helpText={"DID"}
                        />
                      </GridItem>
                    </GridContainer>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showRegisterModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("register-did-document")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleRegisterDidDocument}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("provider")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="did-service-provider"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceProvider}
                    onChange={(e) => {
                      setModalServiceProvider(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "didServiceProvider",
                      id: "didServiceProvider",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("provider")}
                    </MenuItem>
                    {Object.values(ApiService.selfSovereignIdentityProviders)
                      .filter((x) => registerDidDocument[x])
                      .map((provider) => {
                        return (
                          <MenuItem
                            key={provider}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={provider}
                          >
                            {provider}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardBody>
              <h3>{t("verifiable-authorization")}</h3>
              <br />
              <GridContainer
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} style={{ textAlign: "right" }}>
                  {Object.values(acquireVerifiableAuthorization).some(
                    (x) => x
                  ) ? (
                    <Button
                      color="primary"
                      style={{ marginTop: "20px" }}
                      round
                      onClick={() => {
                        setConfirmModalButtonText(t("acquire"));
                        setModalServiceProvider(
                          Object.values(
                            ApiService.selfSovereignIdentityProviders
                          ).filter((x) => acquireVerifiableAuthorization[x])[0]
                        );
                        setShowModal(true);
                      }}
                    >
                      {t("acquire")}
                    </Button>
                  ) : (
                    <Button
                      simple
                      color="rose"
                      className="remove"
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    >
                      {t("valid")}
                    </Button>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {Object.values(showRegisterDidDocument).some((x) => x) && (
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardBody>
                <h3>{t("did-document-0")}</h3>
                <br />
                <GridContainer
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <GridItem xs={12} style={{ textAlign: "right" }}>
                    {Object.values(registerDidDocument).some((x) => x) ? (
                      <Button
                        color="primary"
                        style={{ marginTop: "20px" }}
                        round
                        onClick={() => {
                          setConfirmModalButtonText(t("register"));
                          setModalServiceProvider(
                            Object.values(
                              ApiService.selfSovereignIdentityProviders
                            ).filter((x) => registerDidDocument[x])[0]
                          );
                          setShowRegisterModal(true);
                        }}
                      >
                        {t("register")}
                      </Button>
                    ) : (
                      <Button
                        simple
                        color="rose"
                        className="remove"
                        disabled={true}
                        style={{ marginTop: "20px" }}
                      >
                        {t("registered")}
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <ListIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("ssi-entries")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              <ReactTable
                data={handleReturnData(userSSIEntries)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  {
                    Header: t("id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("provider"),
                    accessor: "provider",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("encrypted"),
                    accessor: "encryptedFormatted",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                }
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

UserSSI.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserSSI;
